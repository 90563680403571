div[class*='roadmap-lines-']:after {
  @apply text-primary;
  font-size: 0.875rem;
  font-weight: normal;
  letter-spacing: -3px;
  left: 0;
  line-height: 1.25rem;  
  position: absolute;
  top: 0;
  width: 100%;
}

.roadmap-lines-1:after {
  content: "___________________________________";
}

.roadmap-lines-2:after {
  content: "___________________________________\A___________________________________";
}

.roadmap-lines-1-md:after {
  content: "___________________";
}

.roadmap-lines-2-md:after {
  content: "___________________\A___________________";
}

.roadmap-lines-1-sm:after {
  content: "_________";
}

.roadmap-lines-2-sm:after {
  content: "_________\A_________";
}

.roadmap-lines-1-xs:after {
  content: "______";
}

.roadmap-lines-2-xs:after {
  content: "______\A______";
}

.roadmap-circle {
  @apply border-primary;
  @apply border;
  border-radius: 50%;
  height: 300px;
  width: 300px;
}

@page {
  size: 215.9mm 279.4mm;
  margin: 2mm;
}





