.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  @apply w-12 bg-gray-700 text-white text-center rounded-md py-2 fixed ml-11 z-10;
  visibility: hidden;
  width: 120px;
  margin-top: -30px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  @apply absolute  absolute top-1/2  border-solid;
  right: 100%;
  margin-top: -5px;
  content: "";
  border-width: 5px;
  border-color: transparent #555 transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}