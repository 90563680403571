.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
  }
  
  .carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }
  
  .carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
  }
  
  .carousel-content > * {
    width: 100%;
    flex-shrink: 0;
  }
  
  .carousel-content.show > * {
    width: calc(100% / 4 - 8px );
    margin: 0  4px;
  }