.public-DraftStyleDefault-block {
  margin: 0;

}

.rdw-link-decorator-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.public-DraftEditor-content a {
  color: -moz-hyperlinkText;
  color: -webkit-link;
  text-decoration: underline;
}