
.DateInput {
    display: none; 
  }
  
  .DateRangePickerInput_arrow {
    display: none; 
  }
  
  
  /* Override default color for selected date */
  .DayPicker__withBorder .CalendarDay__selected {
    background-color: #3498db;/* Custom background color */
    border-color: #b9d6ea; /* Custom border color */
  }
  
  /* Will edit everything selected including everything between a range of dates */
  .CalendarDay__selected_span {
    background: #b9d6ea;
    color: white;
    border: 1px solid white;
  }
  
   /* Will edit selected date or the endpoints of a range of dates */
  .CalendarDay__selected {
    background: 'red';
    color: white;
  }
  
  /* Will edit when hovered over. _span style also has this property */
  .CalendarDay__selected:hover {
    background: #3498db;
    color: white;
  }
  
  /* Colorized range selected */
  .CalendarDay__selected_span:hover {
    background: #3498db;
    color: white;
  }

  /* Will edit when the second date (end date) in a range of dates
  is not yet selected. Edits the dates between your mouse and said date */
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: white;
  }
  
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
  